.AboutUs {
  margin: auto;
  max-width: 1920px;
}
.AboutUsHeader {
  background: var(--Bg-color-ccolor, #e8ebf0);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-top: 74px;
  padding-bottom: 76px;
}
.AboutUsHeaderTop {
  grid-column-start: 2;
  grid-column-end: 12;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-bottom: 82px;
}
.AboutUsHeaderTopLeft {
  grid-column-start: 1;
  grid-column-end: 7;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.KnowUsHead {
  display: flex;
  align-items: center;
  gap: 24px;
  padding-bottom: 53px;
  grid-column-start: 1;
  grid-column-end: 12;
}
.KnowUsHead img {
  max-width: 72px;
}
.KnowUsHead h1 {
  color: var(--color-Title-color, #22495f);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.AboutUsHeaderTopLeft h2 {
  color: var(--color-Title-color, #22495f);
  font-size: 40px;
  font-weight: 700;
  grid-column-start: 1;
  grid-column-end: 13;
}

.AboutUsHeaderTopRight {
  grid-column-start: 8;
  grid-column-end: 13;
}

.AboutUsHeaderTopRight h1 {
  color: var(--color-Title-color, #22495f);
  font-size: 20px;
  font-weight: 700;
}

.AboutUsHeaderTopRight h2 {
  padding-top: 14px;
  color: var(--color-Text-color, #37415162);
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.AboutUsIMG {
  grid-column-start: 2;
  grid-column-end: 12;
  height: 500px;
  position: relative;
}
.AboutUsIMG img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.fadeInOut {
  opacity: 1;
  transition: opacity 2.5s ease-in-out;
}

.fadeOut {
  opacity: 0;
}
.AboutUsTopic {
  background-color: #fff;
  padding-bottom: 96px;
}
.AboutUsMV {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  padding-top: 76px;
  gap: 70px;
}
.AboutUsMission {
  grid-column-start: 2;
  grid-column-end: 8;
}

.AboutUsVision {
  grid-column-start: 8;
  grid-column-end: 14;
}

.AboutUsMV h1 {
  color: var(--color-Title-color, #22495f);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-bottom: 8px;
}
.AboutUsMV h2 {
  color: var(--Tcolor-itle-color, #22495f);
  font-size: 28px;
  font-weight: 700;
  line-height: 150%;
  padding-bottom: 8px;
}
.AboutUsMV h3 {
  color: var(--Tcolor-ext-color, #374151);
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  padding-bottom: 8px;
}
/* 
.Awards {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.Awards h1 {
  text-align: center;
  grid-column-start: 2;
  grid-column-end: 12;
  color: var(--color-Title-color, #22495f);
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 50px;
}
.AwardsBadge {
  grid-column-start: 3;
  grid-column-end: 11;
  display: flex;
  justify-content: space-between;
  padding-bottom: 131px;
}
.AwardsBadgeSection {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.AwardsBadge img {
  max-width: 110px;
  max-height: 92px;
}
.AwardsBadgeSection h2 {
  color: var(--color-Primary-text, #1d2130);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 160%;
  padding: 16px 0px 8px 0px;
}
.AwardsBadgeSection h3 {
  color: var(--color-Primary-text, #1d2130);
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 160%;
}
.AwardsBadgeSection h4 {
  color: var(--color-Primary-text, #1d2130);
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 160%;
  text-transform: uppercase;
  opacity: 0.6;
} */

.Meet {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  /* background: var(--Bg-color-ccolor, #e8ebf0); */
}
.MeetTop {
  text-align: center;
  grid-column-start: 4;
  grid-column-end: 10;
  padding-bottom: 50px;
}
.MeetTop h1 {
  color: var(--color-Title-color, #22495f);
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  line-height: 120%;
}
.MeetTop h2 {
  color: var(--color-Text-color, #374151);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}

.MeetTeam {
  grid-column-start: 2;
  grid-column-end: 12;
  padding-bottom: 82px;
}
.MeetTeamMembers {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  /* padding-bottom: 82px; */
}
.MeetTeamMembers img {
  max-width: 300px;
  max-height: 300px;
  border-radius: 5px;
}
.Members {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Members h1 {
  color: var(--color-Primary-text, #1d2130);
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 160%;
  padding-top: 16px;
}
.Members h2 {
  color: var(--color-Primary-text, #1d2130);
  font-size: 15px;
  font-weight: 500;
  line-height: 160%;
  padding: 8px 0px 16px 0px;
  text-align: justify;
}

.Icons ul {
  display: flex;
  gap: 20px;
}

.ContactUs {
  background: var(--color-Bg-color, #e8ebf0);
  padding-top: 96px;
}
.ContactUsMain {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.ContactUsMainLeft {
  grid-column-start: 2;
  grid-column-end: 6;
}
.ContactUsMainLeftTop {
  display: flex;
  align-items: center;
  gap: 24px;
  padding-bottom: 32px;
}
.ContactUsMainLeftTop img {
  max-width: 72px;
}
.ContactUsMainLeftTop h1 {
  color: var(--color-Primary-text, #1d2130);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.ContactUsMainLeftText {
  color: #1d2130;
  font-size: 56px;
  font-weight: 700;
  line-height: 120%;
}
.ContactUsMainLeftTextTwo {
  color: #1d2130;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  padding-top: 16px;
}
.ContactUsMainRight {
  grid-column-start: 8;
  grid-column-end: 11;
}

.ContactUsMainRightText {
  padding-bottom: 32px;
}
.ContactUsMainRightText h1 {
  color: var(--color-Primary-text, #1d2130);
  font-size: 20px;
  font-weight: 700;
  line-height: 160%;
}
.ContactUsMainRightText h2 {
  color: var(--color-Primary-text, #1d2130);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
.FormSection {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background: var(--color-Bg-color, #e8ebf0);
}
.FormTop {
  grid-column-start: 4;
  grid-column-end: 10;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 32px;
  padding-bottom: 32px;
}
.Extra {
  padding-top: 60px;
}
.FormTop input,
select {
  width: 368px;
  border: none;
  border-bottom: 1px solid black;
  background: transparent;
  outline: none;
  padding: 5px;
}
.FormTop h1 {
  color: var(--color-Primary-text, #1d2130);
  font-size: 12px;
  font-weight: 700;
  line-height: 160%;
}
.map {
  display: flex;
  background-color: #fff;
}

.FormSubject {
  grid-column-start: 4;
  grid-column-end: 10;
  padding-bottom: 32px;
}
.FormSubject textarea {
  width: 100%;
  background-color: var(--Bg-color-color);
  outline: none;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #000;
  height: 156px;
}
.FormButton {
  grid-column-start: 4;
  grid-column-end: 10;
  display: flex;
  justify-content: center;
  padding-bottom: 96px;
}
.FormButton button {
  border-radius: 4px;
  background: var(--color-Primary-color, #096a56);
  padding: 16px 32px;
  color: var(--color-Bg-color);
  font-size: 16px;
  font-weight: 500;
  border: none;
}
.break {
  display: none;
}
/* 
.Codes{
  display: flex;
}
.countryselect{
  width: 100px;
} */

@media screen and (max-width: 1230px) {
  .KnowUsHead {
    padding-bottom: 20px;
  }
  .KnowUsHead h1 {
    font-size: 18px;
  }
  .AboutUsHeaderTopLeft h2 {
    color: var(--color-Title-color, #22495f);
    font-size: 34px;
  }
  .AboutUsHeaderTopRight h1 {
    font-size: 18px;
  }
  .AboutUsHeaderTopRight h2 {
    font-size: 15px;
    line-height: 150%;
  }

  .AboutUsIMG,
  .AboutUsIMG img {
    height: 400px;
  }

  /* .AboutUsMission {
    grid-column-start: 3;
  }
  .AboutUsVision {
    grid-column-end: 13;
  } */
  .AboutUsMV h1 {
    font-size: 16px;
  }
  .AboutUsMV h2 {
    font-size: 18px;
  }
  .MeetTeamMembers {
    gap: 20px;
    justify-content: space-evenly;
  }
}
@media screen and (max-width: 1030px) {
  .MeetTop h1 {
    font-size: 38px;
  }
  .MeetTop {
    grid-column-start: 3;
    grid-column-end: 11;
  }
  .ContactUsMainLeftText {
    font-size: 44px;
  }
}
@media screen and (max-width: 912px) {
  .MeetTeamMembers img {
    max-width: 230px;
  }
  .MeetTeamMembers {
    /* gap: 20px; */
    justify-content: space-evenly;
  }
}
@media screen and (max-width: 860px) {
  .AboutUsMission {
    grid-column-start: 1;
    grid-column-end: 7;
    padding-left: 5vw;
  }

  .AboutUsVision {
    grid-column-start: 7;
    grid-column-end: 13;
  }
  .AboutUsVision {
    padding-right: 5vw;
  }
  .FormTop input {
    width: 35vw;
  }
}

@media screen and (max-width: 760px) {
  .AboutUsMV {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .AboutUsVision {
    padding-left: 5vw;
  }
  .AboutUsVision,
  .AboutUsMission {
    padding-right: 5vw;
  }
  .AboutUsHeaderTop {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 50px;
  }
  .AboutUsMV h1,
  .AboutUsMV h2 {
    padding-bottom: 25px;
  }
  .KnowUsHead {
    flex-direction: column;
    align-items: flex-start;
  }
  .KnowUsHead h1 {
    font-size: 16px;
  }
}
@media screen and (max-width: 740px) {
  .ContactUsMainLeftText {
    font-size: 36px;
  }
  .ContactUsMainRight {
    grid-column-start: 7;
    grid-column-end: 12;
  }
}
@media screen and (max-width: 620px) {
  .ContactUsMainLeftText {
    font-size: 30px;
  }
  .ContactUsMainLeftTop {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 580px) {
  .break {
    display: flex;
  }
  .MeetTop {
    grid-column-start: 2;
    grid-column-end: 12;
  }
  .ContactUsMain {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .FormSection {
    position: relative;
  }
  .FormSubject {
    grid-column-start: 2;
    grid-column-end: 12;
  }

  .FormTopLeft select {
    width: 40vw;
    overflow: auto;
  }

  .FormTopRight select {
    width: 300px;
  }
  .FormTopLeft .emmail {
    width: 300px !important;
  }
}
