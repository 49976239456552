.Blog {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.BlogText {
  grid-column-start: 1;
  grid-column-end: 13;
}
.BlogText h1 {
  text-align: center;
  color: var(--color-Primary-text, #1d2130);
  font-size: 56px;
  font-weight: 700;
  line-height: 120%;
}

.ContactUsMainLeftTop {
  display: flex;
  align-items: center;
  gap: 24px;
  padding-bottom: 32px;
  grid-column-start: 3;
  grid-column-end: 12;
  padding-top: 65px;
}
.ContactUsMainLeftTop img {
  max-width: 72px;
}
.ContactUsMainLeftTop h1 {
  color: var(--color-Primary-text, #1d2130);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

/* CONTRIBUTION */

.Contributions {
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 96px 0px 90px 0px;
}

.Contributionscard {
  grid-column-start: 2;
  grid-column-end: 12;
  /* position: relative; */
  width: 100%; /* Adjust the width as needed */
  height: 384px; /* Adjust the height as needed */
  background-image: url('https://res.cloudinary.com/naijakids/image/upload/q_auto/volunteer_bggugj.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  border-radius: 20px;
}

.ContributionscardText {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(12, 1fr);
}
.ContributionscardText h1 {
  grid-column-start: 2;
  grid-column-end: 12;
  color: var(--color-Bg-color, #e8ebf0);
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 32px;
  padding-top: 91px;
}
.Contributionsbtn {
  grid-column-start: 2;
  grid-column-end: 12;

  display: flex;
  justify-content: center;
  gap: 30px;
}

.Contributionsbtn1 {
  padding: 16px 32px;
  border-radius: 4px;
  background: var(--color-Primary-color, #096a56);
  border: none;
  color: #e8ebf0;
  font-size: 16px;
  font-weight: 700;
}
.Contributionsbtn2 {
  padding: 16px 32px;
  border-radius: 4px;
  background: var(--color-Bg-color, #e8ebf0);
  border: none;
  color: var(--color-Primary-color, #096a56);
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width: 1244px) {
  .ContributionscardText h1 {
    font-size: 38px;
  }
  .Contributionsbtn1,
  .Contributionsbtn2 {
    padding: 12px 24px;
    font-size: 12px;
  }
}

@media screen and (max-width: 915px) {
  .ContributionscardText h1 {
    font-size: 32px;
    padding-bottom: 45px;
    padding-top: 70px;
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .Contributionscard {
    height: 335px;
  }
}

@media screen and (max-width: 768px) {
  .ContributionscardText h1 {
    font-size: 28px;
    padding-bottom: 35px;
  }
  .Contributionscard {
    height: 310px;
  }
}
@media screen and (max-width: 680px) {
  .ContributionscardText h1 {
    font-size: 24px;
  }
  .BlogText h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 430px) {
  .Contributionsbtn1 .Contributionsbtn2 {
    padding: 8px 16px;
    font-size: 10px;
  }
  .ContributionscardText h1 {
    font-size: 22px;
  }

  .Contributionsbtn2,
  .Contributionsbtn1 {
    padding: 10px 20px;
    font-size: 12px;
  }
}
