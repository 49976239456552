* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* transition: 0.2s ease-in-out; */
  font-family: "Nunito Sans", sans-serif;
}
html {
  scroll-behavior: smooth;
}
/* *::-webkit-scrollbar {
  display: none;
} */

:root {
  --color-Bg-color: #e8ebf0;
  --color-Primary-color: #096a56;
  --color-Active-color: #205053;
  --color-Text-color: #374151;
  --color-Title-color: #22495f;
  --color-Bg-color-alt: #e7f2f7;
}
a {
  text-decoration: unset !important;
  color: var(--color-Text-color) !important;
}
ul {
  list-style: none;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px !important;
}
