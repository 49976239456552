.Home {
  margin: auto;
  max-width: 1920px;
}
.hero {
  background-size: cover;
  background-position: top;
  height: 694px;
}

.fadeInOut {
  opacity: 1;
  transition: opacity 2.5s ease-in-out;
}

.fadeOut {
  opacity: 0;
}

.heroContent {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.heroContentText {
  grid-column-start: 2;
  grid-column-end: 8;
}
.heroContentText h1 {
  padding-top: 116px;
  padding-bottom: 75px;
  /* color: var(--color-Bg-color, #e8ebf0); */
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 58px;
  font-weight: 900;
  line-height: 120%;
}
.HeroBtn {
  grid-column-start: 2;
  grid-column-end: 8;
  display: flex;
  align-items: center;
  gap: 32px;
  padding-bottom: 75px;
}
.HeroBtn button {
  border-radius: 4px;
  background: var(--color-Bg-color, #e8ebf0);
  padding: 16px 32px;
  color: var(--color-Text-color, #374151);
  font-size: 16px;
  font-weight: 600;
  border: none;
}
.HeroPlayBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.HeroBtn img {
  max-width: 24px;
}
.HeroPlayBtn h1 {
  color: #fff;
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}
.HeroDetails {
  grid-column-start: 2;
  grid-column-end: 12;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
}
.HeroDetails h1 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
  grid-column-start: 1;
  grid-column-end: 4;
}
.HeroDetails .line {
  border: 0;
  height: 2px;
  width: 100%;
  background: #fff;
  grid-column-start: 4;
  grid-column-end: 10;
}
.HeroDetails h2 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
  grid-column-start: 11;
  grid-column-end: 13;
}

.KnowUs {
  background-color: var(--color-Bg-color);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-top: 80px;
  padding-bottom: 134px;
  align-items: center;
}
.KnowUsLeft {
  grid-column-start: 2;
  grid-column-end: 8;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.CompanyName {
  grid-column-start: 2;
  grid-column-end: 13;
  /* color: var(--color-Title-color, #22495f); */
  font-size: 48px;
  font-weight: 800;
  line-height: 120%;
  padding-bottom: 60px;
  text-align: left;
  color: var(--Text-color, #374151);
}

.KnowUsHead {
  display: flex;
  align-items: center;
  gap: 24px;
  padding-bottom: 35px;
  grid-column-start: 1;
  grid-column-end: 12;
}
.KnowUsHead img {
  max-width: 72px;
}
.KnowUsHead h1 {
  color: var(--color-Title-color, #22495f);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.KnowUsText {
  grid-column-start: 1;
  grid-column-end: 13;
}
.KnowUsText h2 {
  color: var(--color-Title-color, #22495f);
  font-size: 48px;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 40px;
}
.KnowUsDesc {
  grid-column-start: 1;
  grid-column-end: 11;
}
.KnowUsDesc h3 {
  color: var(--Text-color, #374151);
  font-size: 18px;
  font-weight: 400;
  line-height: 190%;
  padding-bottom: 51px;
}
.KnowUsDesc button {
  padding: 16px 32px;
  gap: 10px;
  border-radius: 4px;
  background: var(--color-Primary-color, #096a56);
  color: var(--color-Bg-color, #e8ebf0);
  border: none;
}

.KnowUsRight {
  grid-column-start: 9;
  grid-column-end: 12;
}
.KnowUsRight img {
  max-width: 100%;
  height: 370px;
  width: 400px;
  object-fit: cover;
  border-radius: 15px;
}

.Supporters {
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-top: 46px;
  padding-bottom: 81px;
}
.Supporters h1 {
  color: var(--Title-color, #22495f);
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  grid-column-start: 2;
  grid-column-end: 12;
  padding-bottom: 27px;
  border-bottom: 0px;
}

.Supporters .line {
  height: 2px;
  width: 100%;
  background-color: var(--color-Bg-color);
  grid-column-start: 2;
  grid-column-end: 12;
}
.SupportersIcons {
  grid-column-start: 2;
  grid-column-end: 12;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
  gap: 15px;
  align-items: flex-end;
}
.IconDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SupportersIcons img {
  max-width: 200px;
  max-height: 100px;
}
.SupportersIcons p {
  color: var(--Title-color, #22495f);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  padding-top: 12px;

  /* text-transform: uppercase; */
}

/* KNOW US DIV */

.KnowUsText p {
  color: var(--color-Text-color, #374151);
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  margin-top: -30px;
}

.KnowUsPoints {
  padding-top: 65px;
  grid-column-start: 1;
  grid-column-end: 9;
}
.KnowUsPoint {
  display: flex;
  align-items: flex-start;
  gap: 46px;
}
.KnowUsPoint img {
  max-width: 28px;
  max-height: 28px;
}
.KnowUsPoint h1 {
  color: var(--Title-color, #22495f);
  font-size: 24px;
  font-weight: 700;
}
.KnowUsPoint h2 {
  color: var(--color-Text-color, #374151);
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  padding-top: 8px;
  padding-bottom: 24px;
}



.cardContent h2 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  line-height: 150%;
  padding-bottom: 45px;
}
.cardContent p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  padding-bottom: 47px;
}
.cardContent button {
  padding: 16px 32px;
  border-radius: 4px;
  background: var(--color-Bg-color, #e8ebf0);
  color: var(--color-Primary-color);
  border: none;
}

.Donations {
  background-color: #000;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 96px 0px 96px 0px;
}
.DonationsLeft {
  grid-column-start: 2;
  grid-column-end: 7;
}
.DonationsLeft h1 {
  color: var(--color-Bg-color, #e8ebf0);
  font-size: 48px;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 16px;
}
.DonationsLeft h2 {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  padding-bottom: 32px;
  padding-right: 40px;
  opacity: 0.6;
}
.DonationsLeftDesc {
  display: flex;
  justify-content: space-between;
}
.DonationsLeftDescDetails {
  display: flex;
  align-items: center;
  gap: 6px;
  padding-bottom: 16px;
}
.DonationsLeftDesc img {
  max-width: 16px;
}
.DonationsLeftDesc h1 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  padding-bottom: 0px;
}
.DonationsRight {
  grid-column-start: 9;
  grid-column-end: 12;
}
.DonationsRight img {
  width: 100%;
}

.Contributions {
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 96px 0px 90px 0px;
}

.Contributionscard {
  grid-column-start: 2;
  grid-column-end: 12;
  width: 100%;
  height: 384px;
  background-image: url('https://res.cloudinary.com/naijakids/image/upload/q_auto/volunteer_bggugj.png');
  background-size: cover;
  background-position: center;
  border-radius: 20px;
}

.ContributionscardText {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(12, 1fr);
}
.ContributionscardText h1 {
  grid-column-start: 2;
  grid-column-end: 12;
  color: var(--color-Bg-color, #e8ebf0);
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 32px;
  padding-top: 91px;
}
.Contributionsbtn {
  grid-column-start: 2;
  grid-column-end: 12;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.Contributionsbtn1 {
  padding: 16px 32px;
  border-radius: 4px;
  background: var(--color-Primary-color, #096a56);
  border: none;
  color: #e8ebf0;
  font-size: 16px;
  font-weight: 700;
}
.Contributionsbtn2 {
  padding: 16px 32px;
  border-radius: 4px;
  background: var(--color-Bg-color, #e8ebf0);
  border: none;
  color: var(--color-Primary-color, #096a56);
  font-size: 16px;
  font-weight: 700;
}

.Events {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.Events .OurEvent {
  grid-column-start: 2;
  grid-column-end: 12;
  text-align: left;
  color: var(--color-Title-color, #22495f);

}


.eventsection {
  grid-column-start: 2;
  grid-column-end: 12;
  display: flex;

  gap: 60px;
  justify-content: space-between;
  padding: 50px 0px 99px 0px;
}

.eventcard {
  background-color: #e7f2f7;

  border-radius: 20px;
  width: auto;
  display: flex;
  gap: 50px;

  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.card1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card1 h1 {
  font-size: 28px;

  color: var(--Text-color, #374151);
  font-size: 48px;
  font-weight: 700;
  line-height: 120%;
}
.card1 h2 {
  font-size: 16px;
}
.card1 h3 {
  font-size: 20px;
  color: var(--Text-color, #374151);
  font-weight: 700;
  line-height: 120%;
}
.card2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.card2 h1 {
  font-size: 20px;

  color: black;
  font-size: 28px;
  font-weight: 700;
  line-height: 150%;
}
.card2 h2 {
  font-size: 16px;
  padding: 5px 0px 10px 0px;

  color: var(--color-Title-color, #22495f);
  font-size: 28px;
  font-weight: 700;
  line-height: 150%;
}
.card2 h3 {
  color: var(--color-Title-color, #22495f);
  font-size: 26px;
  font-weight: 700;
  line-height: 150%;
}
.card3 img {
  max-width: 60px;
}
@media screen and (max-width: 1280px) {
  .CompanyName {
    font-size: 36px;
  }
}

@media screen and (max-width: 1244px) {
  .ContributionscardText h1 {
    font-size: 38px;
  }
  .Contributionsbtn1,
  .Contributionsbtn2 {
    padding: 12px 24px;
    font-size: 12px;
  }
  .CompanyName {
    font-size: 30px;
  }
}

@media screen and (max-width: 915px) {
  .ContributionscardText h1 {
    font-size: 32px;
    padding-bottom: 45px;
    padding-top: 70px;
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .Contributionscard {
    height: 335px;
  }
}
@media screen and (max-width: 1450px) {
  .heroContentText h1 {
    font-size: 53px;
  }
  .eventsection {
    gap: 40px;
  }
}
@media screen and (max-width: 1390px) {
  .HeroDetails h2,
  .HeroDetails h1 {
    font-size: 16px;
  }
}
@media screen and (max-width: 1320px) {
  .heroContentText h1 {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 1280px) {
  .KnowUsRight {
    grid-column-start: 8;
  }
  .KnowUsHead h1 {
    font-size: 16px;
  }
  .KnowUsText h2 {
    font-size: 44px;
  }
  .KnowUsDesc h3 {
    font-size: 16px;
  }
  .KnowUsText h2 {
    font-size: 36px;
  }
  .eventsection {
    flex-direction: column;
  }
}
@media screen and (max-width: 1212px) {
  .hero {
    height: 665px;
  }
  .heroContentText h1 {
    font-size: 46px;
  }
  .heroContentText {
    grid-column-start: 2;
    grid-column-end: 10;
  }
  .heroContentText h1 {
    padding-bottom: 85px;
  }
  .HeroDetails {
    gap: 0px;
  }
}
@media screen and (max-width: 1174px) {
  .KnowUsRight img {
    height: 425px;
  }
  .KnowUsText {
    grid-column-end: 12;
  }
  .KnowUsText h2 {
    font-size: 32px;
  }
  .KnowUsDesc h3 {
    line-height: 180%;
  }
  .KnowUsDesc button {
    padding: 12px 23px;
    font-size: 14px;
  }
}

@media screen and (max-width: 988px) {
  .KnowUsRight img {
    height: 100%;
  }
  .HeroDetails {
    display: flex;
    flex-direction: column;
  }

  .SupportersIcons {
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }
  .IconDiv {
    align-items: center;
  }
  .Supporters h1 {
    text-align: center;
  }
}
@media screen and (max-width: 865px) {
  .heroContentText {
    grid-column-end: 12;
  }
  .KnowUsDesc h3 {
    padding-bottom: 25px;
  }
  .KnowUsText h2 {
    padding-bottom: 16px;
  }
  .KnowUsHead {
    padding-bottom: 23px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .KnowUsText p {
    margin-top: 0px;
  }
  .card2 h2,
  .card2 h3 {
    font-size: 16px;
  }
  .card2 h1 {
    font-size: 18px;
  }
  .card1 h1 {
    font-size: 28px;
  }
}
@media screen and (max-width: 768px) {
  .ContributionscardText h1 {
    font-size: 28px;
    padding-bottom: 35px;
  }
  .Contributionscard {
    height: 310px;
  }
}
@media screen and (max-width: 680px) {
  .ContributionscardText h1 {
    font-size: 24px;
  }
  .heroContentText {
    grid-column-end: 12;
  }
  .CompanyName {
    padding-left: 7vw;
  }
  .KnowUs {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 95px;
    padding-bottom: 0px;
  }
  .KnowUsForced {
    padding-bottom: 80px !important;
  }
  .KnowUsLeft {
    padding-left: 50px;
  }
  .KnowUsDesc {
    grid-column-end: 12;
  }
  .KnowUsRight img {
    width: 80vw;
  }
}
@media screen and (max-width: 660px) {
  .heroContentText h1 {
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 628px) {
  .hero {
    height: 510px;
  }

  .heroContentText h1 {
    padding-bottom: 20px;
    padding-top: 70px;
    font-size: 42px;
  }
  .HeroBtn button {
    padding: 9px 18px;
  }
}
@media screen and (max-width: 600px) {
  .HeroBtn {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 571px) {
  .HeroBtn button {
    font-size: 14px;
  }
  .heroContentText h1 {
    font-size: 38px;
  }
  .SupportersIcons {
    flex-direction: column;
  }
  .card3 img {
    max-width: 40px;
  }
  .card1 {
    align-items: flex-start;
  }
  .eventcard {
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
  }
}
@media screen and (max-width: 544px) {
  .HeroBtn button {
    padding: 6px 12px;
  }
}

@media screen and (max-width: 520px) {
  .HeroBtn button {
    padding: 6px 6px;
  }
  .heroContentText h1 {
    padding-top: 40px;
  }
  .KnowUsLeft {
    padding-left: 30px;
  }
  .KnowUsLeftForce {
    padding-left: 20px !important;
  }
  .KnowUsRight img {
    width: 90vw;
  }
  .KnowUsText {
    grid-column-end: 13;
    padding-right: 10px;
  }
  .KnowUsPoint {
    gap: 25px;
  }
  .KnowUsPoints {
    grid-column-end: 12;
  }
}
@media screen and (max-width: 500px) {
  .hero {
    height: 95vh;
  }
  .HeroBtn button,
  .HeroPlayBtn h1 {
    font-size: 12px;
  }
  .HeroBtn {
    gap: 12px;
  }
  .HeroBtn img {
    max-width: 20px;
  }
  .heroContentText,
  .HeroBtn,
  .HeroDetails {
    grid-column-start: 1;
    padding-left: 15px;
    padding-top: 25px;
  }
}
@media screen and (max-width: 470px) {
  .heroContentText h1 {
    font-size: 35px;
  }

  .HeroDetails h2 {
    margin-left: 0px;
  }
}

@media screen and (max-width: 430px) {
  .Contributionsbtn1 .Contributionsbtn2 {
    padding: 8px 16px;
    font-size: 10px;
  }
  .ContributionscardText h1 {
    font-size: 22px;
  }

  .Contributionsbtn2,
  .Contributionsbtn1 {
    padding: 10px 20px;
    font-size: 12px;
  }
  .KnowUsLeft {
    padding-left: 20px;
  }
}
@media screen and (max-width: 360px) {
  .heroContentText h1 {
    font-size: 28px;
  }
  .HeroBtn {
    grid-column-end: 10;
  }
}

@media screen and (max-width: 290px) {
  .hero {
    height: 100vh;
  }
}
