.Footer {
  background-color: black;
  margin: auto;
  max-width: 1920px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-top: 86px;
  padding-bottom: 73px;
}
.FooterLogo {
  grid-column-start: 2;
  grid-column-end: 4;
}
.FooterLogo img {
  max-width: 100%;
  min-width: 150px;
  padding: 10px 0px;
}
.FooterLinks {
  grid-column-start: 4;
  grid-column-end: 9;
  display: flex;
  gap: 100px;
}
.FooterLink ul li:first-child {
  color: var(--color-Bg-color-alt);
}
.FooterLink ul li {
  color: #6a6767;
  padding-bottom: 15px;
}
.FooterSubscribe {
  grid-column-start: 9;
  grid-column-end: 12;
}
.FooterSubscribe h1 {
  color: var(--Bg-color, #e8ebf0);
  font-size: 32px;
  font-weight: 700;
  line-height: 140%;
  padding-bottom: 15px;
}
.btnGroup {
  border-radius: 5px;
  border: 1px solid rgba(235, 240, 249, 0.3);
  display: flex;
  width: auto;
  padding: 0px 0px 0px 10px;
  align-items: center;
  width: 90%;
}
.btnGroup input {
  background: transparent;
  border: none;
  color: var(--color-Bg-color);
  width: 60%;
}
.btnGroup input:focus {
  outline: none;
}
.btnGroup button {
  padding: 10px;
  width: 40%;
  border-radius: 4px;
  background: var(--color-Bg-color, #e8ebf0);
  backdrop-filter: blur(40px);
  border: none;
}

@media screen and (max-width: 1350px) {
  .FooterLinks {
    grid-column-start: 3;
    grid-column-end: 9;
  }
  .FooterLogo {
    grid-column-end: 3;
  }
}

@media screen and (max-width: 1024px) {
  .FooterLinks {
    gap: 45px;
  }
}
@media screen and (max-width: 912px) {
  .FooterLinks {
    gap: 5px;
  }
  .FooterSubscribe h1 {
    font-size: 23px;
  }
  .btnGroup input {
    font-size: 12px;
  }
  .btnGroup button {
    padding: 7px;
    font-size: 9px;
  }
}

@media screen and (max-width: 820px) {
  .FooterLogo img {
    max-width: 200px;
  }
}

@media screen and (max-width: 700px) {
  .FooterLogo {
    grid-column-start: 1;
    grid-column-end: 13;
    padding: 0rem 0rem 3rem 2rem;
  }

  .FooterLinks {
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .FooterLinks {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
  }
  .FooterSubscribe {
    grid-column-start: 1;
    grid-column-end: 8;
    padding-left: 2rem;
  }
}
