.NavbarMain {
  margin: auto;
  max-width: 1920px;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  background: var(--color-Bg-color, #e8ebf0);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  top: 0;
  /* position: sticky; */
  z-index: 9999999;
}
.NavbarLogo {
  grid-column-start: 2;
  grid-column-end: 4;
}
.NavbarLogo img {
  max-width: 100%;
  min-width: 150px;
  padding: 10px 0px;
  max-height: 100px;
}
.NavbarLinks {
  grid-column-start: 5;
  grid-column-end: 8;
  padding: 16px 20px;
}
.NavbarLinks ul {
  display: flex;
  list-style: none;
  justify-content: center;
}
.ActiveTab {
  color: var(--color-Primary-color) !important;
  font-weight: 700;
}
.NavbarLinks ul > * + * {
  margin-left: 40px;
}
.NavbarLinks ul > li {
  font-size: 16px;
}

.NavbarBtn {
  grid-column-start: 11;
  grid-column-end: 11;
}
.NavbarBtn button {
  background-color: var(--color-Primary-color);
  color: #fff;
  padding: 12px 32px;
  border-radius: 5px;
  border: none;
}
.MobileNav {
  display: none;
}

@media screen and (max-width: 1024px) {
  .NavbarLinks {
    grid-column-start: 4;
    grid-column-end: 9;
  }
}
@media screen and (max-width: 780px) {
  .NavbarLogo {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .NavbarLinks {
    grid-column-start: 3;
    grid-column-end: 10;
  }
}

@media screen and (max-width: 660px) {
  .NavbarMain {
    display: none;
  }
  .MobileNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    top: 0;
    /* position: sticky; */
    background-color: var(--color-Bg-color);
  }
  .NavbarLogo img {
    max-width: 50px;

    min-width: 180px;
    padding: 5px 0px;
  }
  .NavbarLinks ul {
    justify-content: center;
    flex-direction: column;
  }
  .NavbarLinks ul > * + * {
    margin-left: 0px;
  }
  .NavbarLinks ul > li {
    font-size: 20px;
    padding: 5px 0px 15px 0px;
  }
  .NavbarLinks {
    padding: 0px;
  }
  ul {
    padding-left: 0rem;
  }
}
